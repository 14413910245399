import React, { ReactElement, useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

import { labelsByLocale } from "../components/locale-context";
import { pageInfoByLocale } from "../queries/page-info-helpers";
import { getLocalizedValue } from "../components/locale-context";
import { localizeUrl } from "../libs/locale";
import { PageSEO } from "../libs/seo";
import { LocationInfo } from "../libs/location";
import { getPreferredLocale, defaultLocale } from "@chweb/commonui";
import { PageInfoData } from "../queries/fragment-page-info";
import { AllLocalesLabels } from "../queries/fragment-all-locales-labels";

interface NotFoundPageProps {
  data: Page404Data,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

export default function NotFoundPage ({ data, pageContext, location }: NotFoundPageProps): ReactElement {
  const [isMounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  },[])

  if(!isMounted)
    return <></>;

  let { locale } = pageContext;

  // redirecting to the proper language
  if (locale === defaultLocale()) {
    locale = getPreferredLocale();
  }

  const labels = getLocalizedValue(labelsByLocale(data.labels.nodes), locale);
  const localPageInfo = getLocalizedValue(
    pageInfoByLocale(data.links.frontmatter.pageId), locale);

  const other = data.links.frontmatter.suggestions.map(s => {
    return getLocalizedValue(pageInfoByLocale(s.pageId), locale);
  });

  const textOptions = data.text.nodes.filter(n => n.fields.locale === locale);
  const htmlContent = textOptions.length ? textOptions[0].html : '';

  // canonical link and no indexing of this page
  const seo = new PageSEO(`${location.origin}/404/`);
  seo.isDisableIndex = true;

  return (
    <Layout locale={locale} location={location} title = { localPageInfo.title } seo = { seo }>
      <h1 title = { localPageInfo.tooltip }>{ localPageInfo.title }</h1>
      <div dangerouslySetInnerHTML = {{ __html: htmlContent }} />
      <ul>
        {
          other.map((o, i) => {
            const isRoot = o.baseUrl === '/';
            const label = isRoot ? labels.pageHomeAltTitle : o.title;
            return <li key = { i.toString() }>
              <Link to = { localizeUrl(o.baseUrl, locale, isRoot) }>{ label }</Link>
            </li>;
          })
        }
      </ul>
    </Layout>
  );
}

interface Page404Data {
  links: {
    frontmatter: {
      pageId: PageInfoData,
      suggestions: {
        pageId: PageInfoData
      }[]
    }
  },
  text: {
    nodes: {
      html: string,
      fields: {
        locale: string
      }
    }[]
  },
  labels: {
    nodes: AllLocalesLabels[]
  }
}

export const query = graphql`query {
  links: markdownRemark(fields: {slug: {eq: "/404/"}}) {
    frontmatter {
      pageId {
        ...PageInfo
      }
      suggestions {
        pageId {
          ...PageInfo
        }
      }
    }
  }
  text: allMarkdownRemark(filter: {fields: {slug: {regex: "/404\\.[a-z]{2}/"}}}) {
    nodes {
      html
      fields {
        locale
      }
    }
  }
  labels: allLabelsTranslatedYaml(filter: {name: {in: [
      "pageHomeAltTitle"
  ]}}) {
    nodes {
      ...AllLocalesLabels
    }
  }
}
`;
